import { template as template_dc1a59bf698e40ba92f96a78ae4c7582 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_dc1a59bf698e40ba92f96a78ae4c7582(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
