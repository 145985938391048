import { template as template_cb5d6727bac44be792fa9d6fa8678408 } from "@ember/template-compiler";
import SearchMenuPanel from "../search-menu-panel";
const SearchMenuWrapper = template_cb5d6727bac44be792fa9d6fa8678408(`
  <div class="search-menu glimmer-search-menu" aria-live="polite" ...attributes>
    <SearchMenuPanel @closeSearchMenu={{@closeSearchMenu}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SearchMenuWrapper;
