import { template as template_f6b53f28165f4aef8944d7c223581240 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f6b53f28165f4aef8944d7c223581240(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
