import { template as template_81cd2659339941d2b5ad12b25f256022 } from "@ember/template-compiler";
const SidebarSectionMessage = template_81cd2659339941d2b5ad12b25f256022(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
