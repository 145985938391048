import { template as template_193e5459f9ed454880e8b83c5fc91f64 } from "@ember/template-compiler";
const FKLabel = template_193e5459f9ed454880e8b83c5fc91f64(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
