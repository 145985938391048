import { template as template_3ce1a3082f1b46238b2f99f7c5d0be1c } from "@ember/template-compiler";
const WelcomeHeader = template_3ce1a3082f1b46238b2f99f7c5d0be1c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
