import { template as template_fae79a88c86b4737ae7a803799ba6cae } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_fae79a88c86b4737ae7a803799ba6cae(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
