import { template as template_2614200e3dc44ff69c3a34efb66fa61f } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
const CategoryTitleBefore = template_2614200e3dc44ff69c3a34efb66fa61f(`
  <PluginOutlet
    @name="category-title-before"
    @outletArgs={{hash category=@category}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryTitleBefore;
